import App from './App.vue'
import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

import '@/bridge/ynjs-sdk.js'

import VConsole from 'vconsole'
new VConsole();

// vant
import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);

import {Toast} from 'vant'
Vue.prototype.$toast = Toast
Vue.config.productionTip = false


import HelloWorld from "./components/HelloWorld";

// 3. 创建 router 实例，然后传 `routes` 配置
// 你还可以传别的配置参数, 不过先这么简单着吧。
const router = new VueRouter({
    routes: [
        {
            path: '/',
            name: 'HelloWorld',
            component: HelloWorld
        }
    ]
})


new Vue({
    router,
    render: h => h(App),
}).$mount('#app')
