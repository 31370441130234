<template>


    <div class="hello1">
        <p>JSBridge 1.0 </p>
        <img :src="imgUrl" width="50" height="50"/>

        <div class="box" name="base">
            <div class="button" @click="getAppData">getAppData</div>
        </div>

        <div class="box" name="utils">
            <div class="button" @click="goBack">goBack</div>
            <div class="button" @click="closeWindow">closeWindow</div>
            <div class="button" @click="setStorage">setStorage</div>
            <div class="button" @click="getStorage">getStorage</div>
        </div>

        <div class="box" name="actionUrl">
            <div class="button" @click="actionWithUrlToast">actionWithUrlToast</div>
            <div class="button" @click="actionWithUrlCall">actionWithUrlCall</div>
        </div>

        <div class="box" name="topBar">
            <div class="button" @click="setTitle">setTitle</div>
            <div class="button" @click="setActionButton">setActionButton</div>
        </div>


        <div class="box" name="image">
            <div class="button" @click="takePhoto">takePhoto</div>
            <div class="button" @click="chooseImage">chooseImage</div>
            <div class="button" @click="previewImage">previewImage</div>
        </div>

        <div class="box" name="videos" v-if="false">
            <div class="button" @click="chooseVideo">chooseVideo</div>
            <div class="button" @click="uploadVideo">uploadVideo</div>
            <div class="button" @click="previewVideo">previewVideo</div>
        </div>

        <div class="box" name="files" v-if="false">
            <div class="button" @click="chooseFile">chooseFile</div>
            <div class="button" @click="uploadFile">uploadFile</div>
            <div class="button" @click="previewFile">previewFile</div>
        </div>

        <div class="box" name="location">
            <div class="button" @click="getLocation">getLocation</div>
            <div class="button" v-if="false" @click="chooseLocation">chooseLocation</div>
            <div class="button" v-if="false" @click="previewLocation">previewLocation</div>
        </div>


    </div>
</template>

<script>
    export default {
        name: "HelloWorld",
        data() {
            return {
                imgUrl: 'https://img01.yzcdn.cn/vant/cat.jpeg',
            };
        },
        mounted() {
            setTimeout(() => {
                window.jsBidgeApi.onContainerResume(ret => {
                    console.log(`onContainerResume ${JSON.stringify(ret)}`);
                });
                window.jsBidgeApi.onContainerPause(ret => {
                    console.log(`onContainerPause ${JSON.stringify(ret)}`);
                });
            }, 500);
        },
        methods: {
            getAppData() {
                let _this = this
                window.jsBidgeApi.getAppData({
                    success(ret) {
                        console.log("getAppData:" + JSON.stringify(ret))
                        _this.$toast(JSON.stringify(ret))
                    }
                });
            },
            goBack() {
                window.jsBidgeApi.goBack();
            },
            closeWindow() {
                window.jsBidgeApi.closeWindow();
            },
            setStorage() {
                let _this = this
                let key = "test";
                let value = "我是storage的value";

                window.jsBidgeApi.setStorage({
                    key,
                    value,
                    success() {
                        console.log(`setStorage success, key:${key} value:${value}`);
                        _this.$toast(`setStorage success, key:${key} value:${value}`)
                    }
                });
            },
            getStorage() {
                let _this = this
                let key = "test";
                window.jsBidgeApi.getStorage({
                    key,
                    success(ret) {
                        console.log("getStorage" + JSON.stringify(ret))
                        _this.$toast(ret.value)
                    }
                });
            },

            actionWithUrlToast() {
                window.jsBidgeApi.actionWithUrl({
                    actionUrl: "wutongdriver://lib/toast?text=测试toast"
                })
            },
            actionWithUrlCall() {
                window.jsBidgeApi.actionWithUrl({
                    actionUrl: "wutongdriver://lib/callPhone?title=联系客服&message=400-401&phone=4000080666"
                })
            },

            setTitle() {
                window.jsBidgeApi.setTitle({
                    title: "我是H5设置的title"
                });
            },
            setActionButton() {
                window.jsBidgeApi.setActionButton({
                    actions: [{
                        iconUrl: "https://img01.yzcdn.cn/vant/cat.jpeg",
                        name: "拨号",
                        actionUrl: "wutongdriver://lib/callPhone?title=联系客服&message=400-401&phone=4000080666"
                    }, {
                        name: "提示",
                        actionUrl: "wutongdriver://lib/toast?text=测试toast"
                    }, {
                        iconUrl: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABDElEQVQ4T6XTvyuHURTH8ZfJJPkLZDHZTMhmUSY/F1Yig1Is5McixWCwmSw2KRIrg8W/oBhMVr8yoFP3qcfty/OUs9zhnPO+n/s59zT5ZzRl/d3oxFFdbhmwhja84w0bdSAFYB2j6EpNh7irAykDvrKGB7RXqSgAw4gn9OIFFzjFfl1A1J3jGjeYxURVc+TLJvbjCn2YTLkA/Rn5GJcwkiCh5hJh8K+RA6IwJvCMOTziDDMYTOc9FgpiI0ArTvCKeeyhBx+YwiqeMJ57kMvcTbcG5BO3SVnU7aAlFDVSUAaF1C1sZ15sohnLVYCADWEasScr6MAAxsKjOoBCUYAWcYyDtC8//kHVyBvmvwHdAzARvzSIhwAAAABJRU5ErkJggg==",
                        name: "提示",
                        actionUrl: "wutongdriver://lib/toast?text=测试BASE64"
                    }],
                    success(ret) {

                    }
                })
            },
            takePhoto() {
                let _this = this;
                window.jsBidgeApi.takePhoto({
                    success(ret) {
                        console.log(`takePhoto success, ret:${JSON.stringify(ret)}`);
                        _this.$toast(JSON.stringify(ret));
                    }
                });
            },
            chooseImage() {
                let _this = this;
                window.jsBidgeApi.chooseImage({
                    count: 3, //可选择数量，0<count<=9
                    success(ret) {
                        console.log(`chooseImage success, ret:${JSON.stringify(ret)}`);
                        _this.$toast(JSON.stringify(ret));
                        _this.imgUrl = ret.result[0].path;
                    },
                    cancel(ret) {
                    }
                });
            },
            previewImage() {
                window.jsBidgeApi.previewImage({
                    urls: ['https://img01.yzcdn.cn/vant/cat.jpeg', 'https://img01.yzcdn.cn/vant/cat.jpeg'], //可以为nativeResourceUrl
                    index: 1, //打开时展示的图片位置
                    success() {
                    }
                });
            },

            getLocation() {
                let _this = this;
                window.jsBidgeApi.getLocation({
                    success(ret) {
                        let address = ret.address; // 详细地址
                        let latitude = ret.latitude; // 纬度，浮点数，范围为90 ~ -90
                        let longitude = ret.longitude; // 经度，浮点数，范围为180 ~ -180。
                        console.log(`getLocation success, ret:${JSON.stringify(ret)}`);
                        _this.$toast(`getLocation success, ret:${JSON.stringify(ret)}`)
                    }
                });
            },
        }
    };
</script>

<style scoped>
    .box::before {
        content: attr(name);
        width: 100%;
        display: block;
        font-size: 20px;
        margin-bottom: 5px;
        text-align: left;
        margin-left: 10px;
        font-weight: bold;
    }

    .box {
        border-top: 1px cadetblue solid;
        padding: 10px 0px;
        display: flex;
        align-items: flex-start;
        justify-items: flex-start;
        flex-wrap: wrap;
    }

    .button {
        display: block;
        padding: 5px;
        margin: 5px 10px;
        border: 1px cadetblue solid;
    }
</style>
